var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "search-attr" }, [
        _c(
          "div",
          [
            _c("a-input", {
              staticStyle: { width: "250px", margin: "5px" },
              attrs: {
                allowClear: "",
                size: "small",
                placeholder: "买家昵称/userId/店铺名"
              },
              model: {
                value: _vm.params.buyerUserName,
                callback: function($$v) {
                  _vm.$set(_vm.params, "buyerUserName", $$v)
                },
                expression: "params.buyerUserName"
              }
            }),
            _c("a-input", {
              staticStyle: { width: "250px", margin: "5px" },
              attrs: {
                allowClear: "",
                size: "small",
                placeholder: "卖家昵称/userId/店铺名"
              },
              model: {
                value: _vm.params.sellerUserName,
                callback: function($$v) {
                  _vm.$set(_vm.params, "sellerUserName", $$v)
                },
                expression: "params.sellerUserName"
              }
            }),
            _c("a-input", {
              staticStyle: { width: "200px", margin: "5px" },
              attrs: {
                allowClear: "",
                size: "small",
                placeholder: "群groupId搜索"
              },
              model: {
                value: _vm.params.groupId,
                callback: function($$v) {
                  _vm.$set(_vm.params, "groupId", $$v)
                },
                expression: "params.groupId"
              }
            }),
            _c("a-input", {
              staticStyle: { width: "150px", margin: "5px" },
              attrs: { size: "small", placeholder: "请输入订单号" },
              model: {
                value: _vm.params.orderNo,
                callback: function($$v) {
                  _vm.$set(_vm.params, "orderNo", $$v)
                },
                expression: "params.orderNo"
              }
            }),
            _c(
              "a-select",
              {
                staticStyle: { width: "120px", margin: "5px" },
                attrs: {
                  allowClear: "",
                  size: "small",
                  placeholder: "群聊or私聊"
                },
                model: {
                  value: _vm.params.chatType,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "chatType", $$v)
                  },
                  expression: "params.chatType"
                }
              },
              [
                _c("a-select-option", { attrs: { value: 0 } }, [
                  _vm._v("群聊")
                ]),
                _c("a-select-option", { attrs: { value: 1 } }, [_vm._v("私聊")])
              ],
              1
            ),
            _c(
              "a-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.search }
              },
              [_vm._v("搜索")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("a-table", {
            attrs: {
              pagination: false,
              loading: _vm.loading,
              columns: _vm.columns,
              "data-source": _vm.data,
              rowKey: function(record, n) {
                return n
              }
            },
            scopedSlots: _vm._u([
              {
                key: "slotProductName",
                fn: function(row) {
                  return _c("div", {}, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.openPage(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(row.productName))]
                    )
                  ])
                }
              },
              {
                key: "takeUserSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    _c("div", { staticClass: "flex-center-center flex-wrap" }, [
                      _c("span", { staticStyle: { color: "green" } }, [
                        _vm._v(_vm._s(row.userOneName) + " "),
                        _c("span", { staticClass: "color-orange" }, [
                          _vm._v("【" + _vm._s(row.oneUserId) + "】")
                        ])
                      ]),
                      _c("span", { staticStyle: { color: "green" } }, [
                        _vm._v("----" + _vm._s(row.userTwoName) + " "),
                        _c("span", { staticClass: "color-orange" }, [
                          _vm._v("【" + _vm._s(row.twoUserId) + "】")
                        ])
                      ])
                    ])
                  ])
                }
              },
              {
                key: "setup",
                fn: function(row) {
                  return _c("div", {}, [
                    row.groupId
                      ? _c(
                          "a",
                          {
                            staticStyle: { "margin-right": "5px" },
                            on: {
                              click: function($event) {
                                return _vm.openModal(row, "v1")
                              }
                            }
                          },
                          [_vm._v("查看记录")]
                        )
                      : _vm._e(),
                    _c(
                      "a",
                      {
                        staticStyle: { "margin-right": "5px" },
                        on: {
                          click: function($event) {
                            return _vm.openModalV2(row, "v2")
                          }
                        }
                      },
                      [_vm._v("本地记录")]
                    )
                  ])
                }
              }
            ])
          }),
          _c(
            "div",
            { staticStyle: { display: "flex", "justify-content": "flex-end" } },
            [
              _c(
                "a-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.handleChangePage("up")
                    }
                  }
                },
                [_vm._v("上一页")]
              ),
              _c(
                "a-button",
                {
                  staticClass: "ml-10",
                  on: {
                    click: function($event) {
                      return _vm.handleChangePage("down")
                    }
                  }
                },
                [_vm._v("下一页")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { centered: "", width: 800, title: "聊天记录", footer: null },
          on: { cancel: _vm.cancelModal },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "div",
            { staticClass: "take-box" },
            [
              _c("a-table", {
                attrs: {
                  pagination: _vm.paginationModal,
                  loading: _vm.loadingModal,
                  columns: _vm.takeColumns,
                  "data-source": _vm.messages,
                  rowKey: function(record, n) {
                    return n
                  }
                },
                on: { change: _vm.changePageModal },
                scopedSlots: _vm._u([
                  {
                    key: "content",
                    fn: function(row) {
                      return _c("div", {}, [
                        row.msgType === "TIMTextElem"
                          ? _c("div", [_vm._v(_vm._s(row.msg))])
                          : _vm._e(),
                        row.msgType === "TIMImageElem"
                          ? _c("img", {
                              staticStyle: {
                                "max-width": "100px",
                                "max-height": "100px"
                              },
                              attrs: { src: row.msg, alt: "" },
                              on: {
                                click: function($event) {
                                  return _vm.previewImg(row)
                                }
                              }
                            })
                          : _vm._e(),
                        row.msgType === "TIMSoundElem"
                          ? _c("div", { staticClass: "width-100" }, [
                              _c("audio", { attrs: { controls: "" } }, [
                                _c("source", {
                                  attrs: { src: row.msg, type: "audio/wav" }
                                })
                              ])
                            ])
                          : _vm._e(),
                        row.msgType === "TIMVideoFileElem" && row.msg
                          ? _c("div", [
                              _c(
                                "video",
                                { attrs: { controls: "", width: "200" } },
                                [
                                  _c("source", {
                                    attrs: { src: row.msg, type: "video/mp4" }
                                  })
                                ]
                              )
                            ])
                          : _vm._e(),
                        row.msgType === "TIMVideoFileElem" && !row.msg
                          ? _c("div", [
                              _vm._v(_vm._s("抱歉！当前视频路径未找到"))
                            ])
                          : _vm._e()
                      ])
                    }
                  },
                  {
                    key: "contentTypeSlot",
                    fn: function(row) {
                      return _c("div", {}, [
                        _c("div", { staticStyle: { color: "green" } }, [
                          _vm._v(_vm._s(_vm.contentTypeMapText[row.msgType]))
                        ])
                      ])
                    }
                  }
                ])
              })
            ],
            1
          )
        ]
      ),
      _c("Audio", { attrs: { src: "" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }