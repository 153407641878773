import { render, staticRenderFns } from "./takeabout.vue?vue&type=template&id=a909aef0&scoped=true&"
import script from "./takeabout.vue?vue&type=script&lang=js&"
export * from "./takeabout.vue?vue&type=script&lang=js&"
import style0 from "./takeabout.vue?vue&type=style&index=0&id=a909aef0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a909aef0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/zhangshilei/Desktop/pro/dq-admin-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a909aef0')) {
      api.createRecord('a909aef0', component.options)
    } else {
      api.reload('a909aef0', component.options)
    }
    module.hot.accept("./takeabout.vue?vue&type=template&id=a909aef0&scoped=true&", function () {
      api.rerender('a909aef0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/cmsPage/dqPlatform/takeabout.vue"
export default component.exports